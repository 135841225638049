import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Homepage from './components/index';

function App() {
  return (
    <Router>
        <Routes>
          <Route path='/' element={<Homepage />} />
        </Routes>
      </Router>
  );
}

export default App;
