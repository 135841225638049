"use client";

import { useState, useEffect, useCallback } from "react";
import Logo from "../assets/logo-white.png";
import MerchantLogo from "../assets/merchant-home.png";
import {
  FaStore,
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaTiktok,
} from "react-icons/fa";

const useTypewriter = (
  text: string,
  delay: number = 150,
  pauseDuration: number = 2000
) => {
  const [displayText, setDisplayText] = useState("");
  const [index, setIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);

  const animate = useCallback(() => {
    setDisplayText((current) =>
      isDeleting ? current.slice(0, -1) : text.slice(0, index + 1)
    );

    if (!isDeleting && index === text.length - 1) {
      setTimeout(() => setIsDeleting(true), pauseDuration);
    } else if (isDeleting && displayText === "") {
      setIsDeleting(false);
      setIndex(0);
    } else {
      setIndex((current) => (isDeleting ? current - 1 : current + 1));
    }
  }, [index, isDeleting, text, pauseDuration]);

  useEffect(() => {
    const timer = setTimeout(animate, isDeleting ? delay / 2 : delay);
    return () => clearTimeout(timer);
  }, [animate, delay, isDeleting]);

  return displayText;
};

export default function Homepage() {
  const [isAnimating, setIsAnimating] = useState(true);
  const displayText = useTypewriter("Tara!", 150, 2000);

  const handleToggleAnimation = (url: string | URL) => {
    // Toggle the animation state
    setIsAnimating((prev) => !prev);

    // Extract the hostname from the URL
    const urlObj = new URL(url);
    const hostname = urlObj.hostname;

    // Open the URL in the same tab
    window.location.href = `https://${hostname}`;
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-customRed text-white p-4">
      <button
        className="absolute top-4 right-8 px-8 py-4 transition"
        onClick={() =>
          handleToggleAnimation("https://register.negosyotayo.com")
        }
      >
        <img src={MerchantLogo} />
      </button>

      <h1
        className="text-4xl md:text-6xl font-bold mb-8 h-[1.5em] text-center tracking-widest"
        style={{ letterSpacing: "0.15em" }} // Adjust letter spacing here as needed
        aria-live="polite"
      >
        {isAnimating ? displayText : "TARA NA"}
      </h1>
      <img src={Logo} alt="Logo" width={480} height={500} className="mb-8" />

      <div className="flex space-x-4 mt-52">
        <FaFacebook size={28} className="cursor-pointer hover:text-gray-300" />
        <FaTiktok size={28} className="cursor-pointer hover:text-gray-300" />
        <FaInstagram size={28} className="cursor-pointer hover:text-gray-300" />
        {/* Tayo logo insert here */}
      </div>
    </div>
  );
}
